import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <section className="about">
        <div className="row">
          {/* <div className="image">
            <img src={filepath + '/assets/images/about-img.svg'} alt="" />
          </div> */}

          <div className="content">
            <h3>Privacy Policy</h3>
            <p>
              At our Digital Learning Platform, we are committed to protecting
              the privacy and security of our users' personal information. This
              Privacy Policy outlines how we collect, use, disclose, and
              safeguard the information you provide to us when accessing and
              using our platform. We encourage you to read this policy carefully
              to understand our practices regarding your personal data.
            </p>
            <p>Information We Collect:</p>
            <p>
              1. Personal Information: When you create an account on our
              platform, we may collect personal information such as your name,
              email address, username, and password.
            </p>
            <p>
              2. Communication Data: We may collect any information that you
              voluntarily provide to us when you communicate with us via email,
              chat, or other channels.
            </p>
            <p>
              3. Usage Data: Our platform collects usage data, including but not
              limited to your IP address, browser type, device information, and
              operating system. This information helps us analyze trends,
              administer the platform, track user activities, and improve our
              services.
            </p>
            <p>How We Use Your Information:</p>
            <p>
              1. Personalization: We use the information collected to
              personalize your learning experience, provide you with relevant
              educational content, and tailor our services to meet your
              individual needs.
            </p>
            <p>
              2. Communication: We may use your contact information to
              communicate with you about platform updates, new features,
              promotional offers, and important announcements related to your
              account.
            </p>
            <p>
              3. Analytics: We use usage data and other analytics to improve our
              platform, identify user preferences, and enhance the effectiveness
              of our educational resources.
            </p>
            <p>
              4. Compliance and Legal Obligations: We may use and disclose your
              information to comply with applicable laws, regulations, legal
              processes, and enforce our terms of service or protect our rights
              and the rights of our users.
            </p>
            <p>Data Security:</p>
            <p>
              We employ industry-standard security measures to protect your
              personal information from unauthorized access, alteration,
              disclosure, or destruction. These measures include secure server
              connections, encryption, firewalls, and regular security audits.
              However, no method of transmission over the internet or electronic
              storage is 100% secure, and we cannot guarantee absolute security
              of your data.
            </p>
            <p>Data Retention:</p>
            <p>
              We will retain your personal information for as long as necessary
              to fulfill the purposes outlined in this Privacy Policy, unless a
              longer retention period is required or permitted by law.
            </p>
            <p>Updates to the Privacy Policy:</p>
            <p>
              We reserve the right to update or modify this Privacy Policy at
              any time. We will notify you of any material changes by posting
              the revised policy on our platform or through other communication
              channels. Your continued use of our platform after any such
              changes constitutes your acceptance of the updated Privacy Policy.
            </p>
            <p>Contact Us:</p>
            <p>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or the privacy practices of our Digital Learning
              Platform, please contact us at , Address : Moyna (Near SBI BANK -
              Moyna), Purba Medinipur, West Bengal, india - 721629. Phone : +91
              8276866745 and Email : smhubonline@gmail.com.
            </p>
            <p>Last updated: 10-06-2023</p>
            <p></p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
