export const register = () => `/register`;
export const login = () => `/login`;
export const accountVerificationLinkCreate = () => `/account-verification-link-create`;
export const accountVerification = (token) => `/account-verification/${token}`;
export const forgotPasswordEmail = () => `/forgot-password-email`;
export const forgotPasswordOtp = () => `/forgot-password-otp`;
export const forgotPasswordNewPassword = () => `/forgot-password-new-password`;
export const dashboard = () => `/`;
export const about = () => `/about`;
export const teachers = () => `/teachers`;
export const contact = () => `/contact`;
export const privacyPolicy = () => `/privacy-policy`;
export const termsAndCondition = () => `/terms-condition`;

export const tags = () => `/tags`;

export const posts = () => `/posts`;
export const postView = (slug) => `/post/${slug}`;
export const tagView = (slug) => `/tag/${slug}`;
export const postCreate = () => `/post-create`;
export const postEdit = (userId,slug) => `/post-edit/${userId}/${slug}`;
export const postDelete = (userId,slug) => `/post-delete/${userId}/${slug}`;
export const myProfilePost = () => `/my-profile-post`;

export const myProfile = () => `/my-profile`;
export const myProfileUpdate = () => `/my-profile-update`;
export const myPasswordUpdate = () => `/my-password-update`;

export const courses = () => `/courses`;
export const courseView = (slug) => `/course/${slug}`;
export const courseCreate = () => `/course-create`;
export const courseEdit = (userId,slug) => `/course-edit/${userId}/${slug}`;
export const courseDelete = (userId,slug) => `/course-delete/${userId}/${slug}`;
export const myProfileCourse = () => `/my-profile-course`;
export const myProfileCourseView = (slug) => `/my-profile-course-view/${slug}`;

export const feed = () => `/feed`;
export const sitemap = () => `/sitemap`;
export const sitemapcourse = () => `/sitemap-course`;
export const sitemappost = () => `/sitemap-post`;

export const postAddtoCourse = (userId,slug) => `/post-addto-course/${userId}/${slug}`;
export const postEdittoCourse = (userId,postInCourseId) => `/post-editto-course/${userId}/${postInCourseId}`;

export const tools = () => `/tools`;
export const imageCompression = () => `/tool/image-compression`;
export const imageConverter = () => `/tool/image-converter`;

export const nearToMe = () => `https://neartome.digitolearn.com`;
