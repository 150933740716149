import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import { Link, useNavigate } from 'react-router-dom';
import { tagAllAsync, setPage } from '../redux/slices/TagSlice';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

const Tags = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { tagLists, pageCount, hasMore } = useSelector(
    (state) => state.tagReducer
  );

  useEffect(() => {
    var page = 1;
    dispatch(setPage(page));
    dispatch(tagAllAsync({ page, toast, navigate, dispatch }));
  }, [dispatch, navigate]);

  const nextPage = () => {
    var page = pageCount + 1;
    dispatch(tagAllAsync({ page, toast, navigate, dispatch }));
    dispatch(setPage(page));
  };

  return (
    <>
      <section className="courses">
        <h1 className="heading">Our Tags</h1>
        <InfiniteScroll
          dataLength={tagLists.length}
          next={nextPage}
          hasMore={hasMore}
          loader={
            <div style={{ width: '100%' }} className="btn">
              Loading...
            </div>
          }
          endMessage={
            <div style={{ width: '100%' }} className="btn">
              No more tags
            </div>
          }
          scrollThreshold={0.9} // Optional threshold to trigger next load
        >
          <div className="box-container">
            {tagLists &&
              tagLists.length > 0 &&
              tagLists.map((tag, i) => (
                <Link to={`/tag/${tag?.slug}`} key={tag?.id ? tag?.id : i}>
                  <div className="box">
                    <div className="tutor">
                      <img
                        src={filepath + '/assets/static/companylogo.webp'}
                        alt="avatar"
                      />
                      <div className="info">
                        <h3>{'Digitolearn'}</h3>
                        <span>
                          {tag?.createdAt
                            ? moment(tag?.createdAt).format(
                                'MMMM Do YYYY, h:mm:ss a'
                              )
                            : null}
                        </span>
                      </div>
                    </div>
                    <div className="thumb">
                      <img
                        src={
                          tag?.imageUrl
                            ? tag.imageUrl
                            : tag?.image
                            ? apiFilepath + '/uploads/post/' + tag.image
                            : filepath + '/assets/static/companylogo.webp'
                        }
                        alt="tag"
                        onError={(e) => {
                          e.currentTarget.src =
                            filepath + '/assets/static/companylogo.webp';
                        }}
                      />
                      <span>
                        <i className="fas fa-eye"></i>
                        {tag.countPost ? ` ${tag.countPost} posts` : ''}
                      </span>
                    </div>
                    <h3 className="title">{tag?.name ? tag?.name : null}</h3>
                  </div>
                </Link>
              ))}
          </div>
        </InfiniteScroll>
      </section>
    </>
  );
};

export default Tags;
