import axios from 'axios';
import { apiBase } from './urlConfig';

const api = axios.create({
  baseURL: apiBase,
});

api.interceptors.request.use((req) => {
  if (localStorage.getItem('auth')) {
    const authData = JSON.parse(localStorage.getItem('auth'));
    const tokenId = authData?.token;
    req.headers.Authorization = `Bearer ${tokenId}`;
  }
  return req;
});

export const register = (userData) => api.post('/user/register', userData);
export const accountVerification = (userData) => api.post('/user/account-activation',userData);
export const accountVerificationLinkCreate = (userData) => api.post('/user/account-activation-link-create',userData);
export const forgotPasswordEmail = (userData) => api.post('/user/forgot-password-email', userData);
export const forgotPasswordOtp = (userData) => api.post('/user/forgot-password-otp', userData);
export const forgotPasswordNewPassword = (userData) => api.post('/user/forgot-password-new-password', userData);
export const login = (userData) => api.post('/user/login', userData);

export const logingoogle = (userData) => api.post('/user/logingoogle', userData);

export const profileUpdate = (profileData,id) => api.put(`/user/profile/${id}`, profileData);
export const passwordUpdate = (profileData,id) => api.put(`/user/password/${id}`, profileData);
export const userAll = () => api.get(`/user`);

export const tagAll = (page) => api.get(`/tag?page=${page}`);
export const tagShow = (slug) => api.get(`/tag/${slug}`);

export const postAll = (authid,page,search) => api.get(`/post/list?authid=${authid}&page=${page}&search=${search}`);
export const postAllByTag = (page,tagId) => api.get(`/post/list-by-tag?page=${page}&tagId=${tagId}`);
 
export const postCreate = (postData,id) => api.post(`/post/${id}`, postData);
export const ProfilePost = (id,page,search) => api.get(`/post/profile?id=${id}&page=${page}&search=${search}`);
export const postShow = (slug) => api.get(`/post/${slug}`);
export const postUpdate = (postData,id,slug) => api.put(`/post/${id}/${slug}`, postData);
export const postDelete = (id,slug) => api.delete(`/post/delete/${id}/${slug}`);


export const commentAll = (postId,page) => api.get(`/comment/list?postId=${postId}&page=${page}`); 
export const commentCreate = (commentData,userId) => api.post(`/comment/${userId}`, commentData);
export const commentUpdate = (commentData,userId,commentId) => api.put(`/comment/${userId}/${commentId}`, commentData);
export const commentDelete = (userId,commentId) => api.delete(`/comment/delete/${userId}/${commentId}`);

export const likeCreate = (likeData) => api.post(`/like-view/like`, likeData);
export const likeCount = (likeData) => api.post(`/like-view/like-count`, likeData);
export const viewCreate = (viewData) => api.post(`/like-view/view`, viewData);


export const courseAll = () => api.get(`/course`); 
export const courseCreate = (courseData,id) => api.post(`/course/${id}`, courseData);
export const ProfileCourse = (id) => api.get(`/course/profile/${id}`);
export const courseShow = (slug) => api.get(`/course/${slug}`);
export const postInCourseShow = (postInCourseId) => api.get(`/course/post-in-course-show/${postInCourseId}`);
export const courseUpdate = (courseData,id,slug) => api.put(`/course/${id}/${slug}`, courseData);

export const postAddtoCourse = (courseData,id,courseSlug) => api.put(`/course/post-addto-course/${id}/${courseSlug}`, courseData);
export const postEdittoCourse = (courseData,id,courseSlug) => api.put(`/course/post-editto-course/${id}/${courseSlug}`, courseData);

