import React, { useEffect, useState } from 'react';
import { ProfilePostAsync, postDeleteAsync,setPage } from '../redux/slices/PostSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import * as url from '../helpers/url';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

const MyProfilePost = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { auth } = useSelector((state) => state.authReducer);
  const [id] = useState(auth?.id);
  const { profilePostLists,pageCount,hasMore } = useSelector((state) => state.postReducer);

  const [search, setSearch] = useState('');

  useEffect(() => {
      var page = 1;
      dispatch(setPage(page));
      dispatch(ProfilePostAsync({ id, page, search, toast, navigate,dispatch }));
  }, [dispatch, navigate, id, search]);


  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const postDeleteFun = (id, slug) => {
    dispatch(postDeleteAsync({ id, slug, navigate, toast }));
  };


  const nextPage = () => {
    var page = pageCount + 1;
    dispatch(ProfilePostAsync({ id, page, search, toast, navigate,dispatch }));
    dispatch(setPage(page));
  };

  return (
    <>
      {/* <section className="playlist-details">
        <h1 className="heading">My All posts</h1>

        <div className="row">
          <div className="column">
            <form action="" method="post" className="save-playlist">
              <button type="submit">
                <i className="far fa-bookmark"></i> <span>save playlist</span>
              </button>
            </form>

            <div className="thumb">
              <img src={filepath +'/assets/images/thumb-1.png'} alt="" />
              <span>10 videos</span>
            </div>
          </div>
          <div className="column">
            <div className="tutor">
              <img src={filepath +'/assets/images/pic-2.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <span>21-10-2022</span>
              </div>
            </div>

            <div className="details">
              <h3>complete HTML tutorial</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum
                minus reiciendis, error sunt veritatis exercitationem deserunt
                velit doloribus itaque voluptate.
              </p>
              <Link to={url.postCreate()} className="inline-btn">
                Create a Post
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <section className="playlist-videos">
        <h1 className="heading">My Posts List</h1>
        <input
          style={{ width: '100%', margin: '1rem', padding: '1rem' }}
          type="text"
          required
          placeholder="search my post..."
          maxLength="100"
          value={search}
          onChange={handleSearch}
        />
        <InfiniteScroll
          dataLength={profilePostLists.length}
          next={nextPage}
          hasMore={hasMore}
          loader={
            <div style={{ width: '100%' }} className="btn">
              Loading...
            </div>
          }
          endMessage={
            <div style={{ width: '100%' }} className="btn">
              No more posts
            </div>
          }
          scrollThreshold={0.9} // Optional threshold to trigger next load
        >
          <div className="box-container">
            {profilePostLists &&
              profilePostLists.map((post, i) => (
                <div className="box" key={i}>
                  <Link to={`/post/${post?.slug}`}>
                    <i className="fas fa-eye"></i>
                    <img
                      src={
                        post?.fileUrl
                          ? post.fileUrl
                          : post?.file
                          ? apiFilepath + '/uploads/post/' + post.file
                          : filepath + '/assets/static/no-image.jpg'
                      }
                      alt="post"
                      onError={(e) => {
                        e.currentTarget.src =
                          filepath + '/assets/static/no-image.jpg';
                      }}
                    />
                    <h3>{post?.title ? post?.title : null}</h3>
                  </Link>
                  {parseInt(post?.belongsToUser?.id) === parseInt(id) && (
                    <Link
                      to={url.postEdit(post?.userId, post?.slug)}
                      className="btn"
                    >
                      Edit
                    </Link>
                  )}
                  {parseInt(post?.belongsToUser?.id) === parseInt(id) && (
                    <div
                      onClick={() => postDeleteFun(post?.userId, post?.slug)}
                      className="btn"
                    >
                      Delete
                    </div>
                  )}
                </div>
              ))}
          </div>
        </InfiniteScroll>
      </section>
    </>
  );
};

export default MyProfilePost;
