import React from 'react';
import { filepath } from '../helpers/urlConfig';
import { Link } from 'react-router-dom';
import * as url from '../helpers/url';

const About = () => {
  return (
    <>
      <section className="about">
        <div className="row">
          <div className="image">
            <img src={filepath + '/assets/static/NOCFOLF83536987N.webp'} alt="" />
          </div>

          <div className="content">
            <h3>why choose us?</h3>
            <p>
              Welcome to our Digital Learning Platform, a revolutionary
              educational platform designed to foster seamless interaction and
              learning between students and teachers. Our mission is to empower
              individuals with knowledge and skills in a digital environment
              that promotes collaboration, engagement, and lifelong learning.
            </p>
            <p>
              At our Digital Learning Platform, we understand that education is
              not confined to the four walls of a traditional classroom. We
              believe in harnessing the power of technology to create a dynamic
              and immersive learning experience that transcends physical
              boundaries. Through our platform, students and teachers can
              connect with each other, share ideas, and engage in a rich
              educational journey.
            </p>
            {/* <a href="courses.html" className="inline-btn">
              our courses
            </a> */}
          </div>
        </div>
        <div className="box-container">
          <Link to={url.courses()}>
            <div className="box">
              <i className="fas fa-graduation-cap"></i>
              <div>
                <h3>+10k</h3>
                <p>Our courses</p>
              </div>
            </div>
          </Link>

          <Link to={url.posts()}>
            <div className="box">
              <i className="fas fa-sticky-note"></i>
              <div>
                <h3>+40k</h3>
                <p>Our Posts</p>
              </div>
            </div>
          </Link>
          <Link to={url.teachers()}>
            <div className="box">
              <i className="fas fa-chalkboard-user"></i>
              <div>
                <h3>+2k</h3>
                <p>Our Teachers</p>
              </div>
            </div>
          </Link>
          {/* <div className="box">
            <i className="fas fa-briefcase"></i>
            <div>
              <h3>100%</h3>
              <p>job placement</p>
            </div>
          </div> */}
        </div>
      </section>

      {/* <section className="reviews">
        <h1 className="heading">student's reviews</h1>

        <div className="box-container">
          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-2.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-3.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-4.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-5.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-6.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Necessitatibus, suscipit a. Quibusdam, dignissimos consectetur.
              Sed ullam iusto eveniet qui aut quibusdam vero voluptate libero
              facilis fuga. Eligendi eaque molestiae modi?
            </p>
            <div className="student">
              <img src={filepath + '/assets/images/pic-7.jpg'} alt="" />
              <div>
                <h3>john deo</h3>
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star-half-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default About;
