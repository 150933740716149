import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { apiFilepath,filepath } from '../helpers/urlConfig';
import * as url from '../helpers/url';
import { courseShowAsync } from '../redux/slices/CourseSlice';
import moment from 'moment';

const MyProfileCourseView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { slug } = useParams();
  const { auth } = useSelector((state) => state.authReducer);
  const [id] = useState(auth?.id);
  const { course } = useSelector((state) => state.courseReducer);

  useEffect(() => {
    dispatch(courseShowAsync({ slug, toast }));
  }, [slug, dispatch, navigate]);

  return (
    <>
      <section className="playlist-details">
        <h1 className="heading">My Course View</h1>

        <div className="row">
          <div className="column">
            {/* <form action="" method="post" className="save-playlist">
              <button type="submit">
                <i className="far fa-bookmark"></i> <span>save playlist</span>
              </button>
            </form> */}

            <div className="thumb">
              {course && (
                <img
                  src={apiFilepath + '/uploads/course/' + course?.file}
                  alt="avatar"
                  onError={(e) => {
                    e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                  }}
                />
              )}
              {/* <span>10 videos</span> */}
            </div>
          </div>
          <div className="column">
            <div className="tutor">
              <img
                src={
                  course?.belongsToUser?.avatar
                    ? apiFilepath +
                      '/uploads/avatar/' +
                      course?.belongsToUser?.avatar
                    : filepath +'/assets/images/company-logo-1658436134701.png'
                }
                alt="avatar"
                onError={(e) => {
                  e.currentTarget.src = filepath +'/assets/images/user.png';
                }}
              />
              <div>
                <h3>
                  {course?.belongsToUser?.name
                    ? course?.belongsToUser?.name
                    : 'User'}
                </h3>
                <span>
                  {course?.createdAt
                    ? moment(course?.createdAt).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )
                    : null}
                </span>
              </div>
            </div>

            <div className="details">
              <h3>{course && course.title ? course.title : ''}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    course && course.description ? course.description : ''
                }}
              ></p>
              {/* <Link to={url.postCreate()} className="inline-btn">
                Create a Post
              </Link> */}
            </div>
          </div>
        </div>
      </section>

      <section className="playlist-videos">
        <h1 className="heading">Posts List</h1>

        <div className="box-container">
          {course.hasManyPostInCourses &&
            course.hasManyPostInCourses.map((postcourse, i) => (
              <div className="box" key={postcourse?.id ? postcourse?.id : i}>
                <Link to={url.postView(postcourse?.belongsToPost?.slug)}>
                  <i className="fas fa-eye"></i>
                  {postcourse?.belongsToPost?.file ? (
                    <img
                      src={
                        apiFilepath +
                        '/uploads/post/' +
                        postcourse?.belongsToPost?.file
                      }
                      alt="post"
                      onError={(e) => {
                        e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                      }}
                    />
                  ) : (
                    <img
                      src={filepath +'/assets/static/no-image.jpg'}
                      alt="post"
                      onError={(e) => {
                        e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                      }}
                    />
                  )}
                  <h1>Post No : {postcourse?.postno
                      ? postcourse?.postno
                      : null}</h1>
                  <h3>
                    {postcourse?.belongsToPost?.title
                      ? postcourse?.belongsToPost?.title
                      : null}
                  </h3>
                </Link>
                {parseInt(course?.belongsToUser?.id) ===
                  parseInt(id) && (
                  <Link
                    to={url.postEdittoCourse(
                      course?.belongsToUser?.id,
                      postcourse?.id
                    )}
                    className="btn"
                  >
                    Edit
                  </Link>
                )}
                {parseInt(course?.belongsToUser?.id) ===
                  parseInt(id) && (
                  <Link
                    to={url.postDelete(
                      course?.belongsToUser?.id,
                      postcourse?.id
                    )}
                    className="delete-btn"
                  >
                    Delete
                  </Link>
                )}
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default MyProfileCourseView;
