import React from 'react';
import { filepath } from '../helpers/urlConfig';
import { Link } from 'react-router-dom';


const Tools = () => {


  return (
    <>
      <section className="courses">
        <h1 className="heading">our tools</h1>

        <div className="box-container">
          <Link to={`/tool/image-compression`}>
            <div className="box">
              <div className="thumb">
                <img
                  src={filepath + '/assets/images/image-compression.png'}
                  alt="post"
                  onError={(e) => {
                    e.currentTarget.src =
                      filepath + '/assets/static/no-image.jpg';
                  }}
                />
                <span>
                  <i className="fas fa-eye"></i>
                </span>
              </div>
              <h3 className="title">{'Image Compression'}</h3>
            </div>
          </Link>
          <Link to={`/tool/image-converter`}>
            <div className="box">
              <div className="thumb">
                <img
                  src={filepath + '/assets/images/image-converter.webp'}
                  alt="post"
                  onError={(e) => {
                    e.currentTarget.src =
                      filepath + '/assets/static/no-image.jpg';
                  }}
                />
                <span>
                  <i className="fas fa-eye"></i>
                </span>
              </div>
              <h3 className="title">{'Image Converter'}</h3>
            </div>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Tools;
