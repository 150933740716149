import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { tagShowAsync } from '../redux/slices/TagSlice';
import { postAllByTagAsync, setPage } from '../redux/slices/PostSlice';
import InfiniteScroll from 'react-infinite-scroll-component';

const TagView = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { slug } = useParams();
  const [tagName, setTagName] = useState('');
  const [tagImage, setTagImage] = useState('');
  const [tagImageUrl, setTagImageUrl] = useState('');
  const [tagDate, setTagDate] = useState('');
  const [tagPostCount, setTagPostCount] = useState('');

  const { postByTagLists, pageCount, hasMore } = useSelector(
    (state) => state.postReducer
  );
  const { tag } = useSelector((state) => state.tagReducer);

  useEffect(() => {
    dispatch(tagShowAsync({ slug, toast }));
  }, [slug, dispatch]);

  useEffect(() => {
    if (tag) {
      setTagName(tag.name);
      setTagImage(tag.image);
      setTagImageUrl(tag.imageUrl);
      setTagDate(tag.createdAt);
      setTagPostCount(tag.countPost);
      const tagId = tag.id;
      var page = 1;
      dispatch(setPage(page));
      dispatch(postAllByTagAsync({ page, tagId, toast, navigate, dispatch }));
    }
  }, [dispatch, navigate, tag]);

  const nextPage = () => {
    const tagId = tag.id;
    var page = pageCount + 1;
    dispatch(postAllByTagAsync({ page, tagId, toast, navigate, dispatch }));
    dispatch(setPage(page));
  };

  return (
    <>
      <section className="playlist-details">
        <h1 className="heading">Posts about tag</h1>

        <div className="row">
          <div className="column">
            <form action="" method="post" className="save-playlist">
              {/* <button type="submit">
                <i className="far fa-bookmark"></i> <span>save playlist</span>
              </button> */}
            </form>

            <div className="thumb">
              <img
                src={
                  tagImageUrl
                    ? tagImageUrl
                    : tagImage
                    ? apiFilepath + '/uploads/post/' + tagImage
                    : filepath + '/assets/static/companylogo.webp'
                }
                alt="tag"
                onError={(e) => {
                  e.currentTarget.src =
                    filepath + '/assets/static/companylogo.webp';
                }}
              />
              <span>{tagPostCount ? `${tagPostCount} posts` : ''}</span>
            </div>
          </div>
          <div className="column">
            <div className="tutor">
              <img src={filepath + '/assets/static/companylogo.webp'} alt="" />
              <div>
                <h3>{'Digitolearn'}</h3>
                <span>
                  {tagDate
                    ? moment(tagDate).format('MMMM Do YYYY, h:mm:ss a')
                    : null}
                </span>
              </div>
            </div>

            <div className="details">
              <h3>{tagName}</h3>
              <p>
                Explore all posts to grasp the full picture of this topic.
                Access multiple viewpoints for a clearer understanding, and gain
                insight from diverse perspectives on this topic.
              </p>
              {/* <Link to={url.postCreate()} className="inline-btn">
                Create a Post
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      <InfiniteScroll
        dataLength={postByTagLists.length}
        next={nextPage}
        hasMore={hasMore}
        loader={
          <div style={{ width: '100%' }} className="btn">
            Loading...
          </div>
        }
        endMessage={
          <div style={{ width: '100%' }} className="btn">
            No more posts
          </div>
        }
        scrollThreshold={0.9} // Optional threshold to trigger next load
      >
        {postByTagLists && postByTagLists.length > 0 && (
          <section className="courses">
            <h1 className="heading">All Posts</h1>

            <div className="box-container">
              {postByTagLists &&
                postByTagLists.map((postByTag, i) => (
                  <Link to={`/post/${postByTag?.slug}`} key={i}>
                    <div className="box">
                      <div className="tutor">
                        <img
                          src={
                            postByTag?.belongsToUser?.avatar
                              ? apiFilepath +
                                '/uploads/avatar/' +
                                postByTag?.belongsToUser?.avatar
                              : filepath +
                                '/assets/images/company-logo-1658436134701.png'
                          }
                          alt="avatar"
                          onError={(e) => {
                            e.currentTarget.src =
                              filepath + '/assets/images/user.png';
                          }}
                        />
                        <div className="info">
                          <h3>
                            {postByTag?.belongsToUser?.name
                              ? postByTag?.belongsToUser?.name
                              : 'User'}
                          </h3>
                          <span>
                            {postByTag?.publishTime
                              ? moment(postByTag?.publishTime).format(
                                  'MMMM Do YYYY, h:mm:ss a'
                                )
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="thumb">
                        <img
                          src={
                            postByTag?.fileUrl
                              ? postByTag.fileUrl
                              : postByTag?.file
                              ? apiFilepath + '/uploads/post/' + postByTag.file
                              : filepath + '/assets/static/no-image.jpg'
                          }
                          alt="postByTag"
                          onError={(e) => {
                            e.currentTarget.src =
                              filepath + '/assets/static/no-image.jpg';
                          }}
                        />
                        <span>
                          <i className="fas fa-eye"></i>
                        </span>
                      </div>
                      <h3 className="title">
                        {postByTag?.title ? postByTag?.title : null}
                      </h3>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <i className="far fa-heart"></i>
                          <span>
                            {' '}
                            {postByTag?.likes ? postByTag?.likes : 0} Likes
                          </span>
                        </div>
                        <div>
                          <i className="far fa-eye"></i>
                          <span>
                            {' '}
                            {postByTag?.views ? postByTag?.views : 0} Views
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </section>
        )}
      </InfiniteScroll>
    </>
  );
};

export default TagView;
