import React from 'react';
// import { Link } from 'react-router-dom';
import * as url from '../helpers/url';
import { filepath } from '../helpers/urlConfig';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  let body = document.body;
  let navigate = useNavigate();

  const closeBtnClickFun = () => {
    let sideBar = document.querySelector('.side-bar');
    sideBar.classList.remove('active');
    body.classList.remove('active');
  };

  const openDashboardFun = () => {
    closeBtnClickFun();
    navigate(url.dashboard());
  };

  const openContactFun = () => {
    closeBtnClickFun();
    navigate(url.contact());
  };

  const openCourseCreateFun = () => {
    closeBtnClickFun();
    navigate(url.courseCreate());
  };

  const openPostCreateFun = () => {
    closeBtnClickFun();
    navigate(url.postCreate());
  };

  const openAboutFun = () => {
    closeBtnClickFun();
    navigate(url.about());
  };

  const openTagsFun = () => {
    closeBtnClickFun();
    navigate(url.tags());
  };

  const openCoursesFun = () => {
    closeBtnClickFun();
    navigate(url.courses());
  };

  const openNearToMeFun = () => {
    closeBtnClickFun();
    window.location.href = url.nearToMe();
  };

  const openToolsFun = () => {
    closeBtnClickFun();
    window.location.href = url.tools();
  };

  return (
    <div className="side-bar">
      <div id="close-btn" onClick={() => closeBtnClickFun()}>
        <i className="fas fa-times"></i>
      </div>

      <div className="profile">
        <img
          src={filepath + '/assets/static/companylogo.webp'}
          className="image"
          alt=""
        />
        <h3 className="name">DigitoLearn</h3>
        <p className="role">
          Share, Connect, and Learn Together on a Dynamic Digital Platform
        </p>
        <div className="btn" onClick={openPostCreateFun}>
          Create a Post
        </div>
        <div className="btn" onClick={openCourseCreateFun}>
          Create a Course
        </div>
      </div>

      <nav className="navbar">
        <div className="link" onClick={openDashboardFun}>
          <i className="fas fa-home"></i>
          <span>Home</span>
        </div>
        <div className="link" onClick={openTagsFun}>
          <i className="fas fa-sticky-note"></i>
          <span>Tags</span>
        </div>
        <div className="link" onClick={openAboutFun}>
          <i className="fas fa-question"></i>
          <span>About Us</span>
        </div>
        <div className="link" onClick={openCoursesFun}>
          <i className="fas fa-graduation-cap"></i>
          <span>Courses</span>
        </div>
        {/* <Link className="link" to={url.teachers()}>
          <i className="fas fa-chalkboard-user"></i>
          <span>Teachers</span>
        </Link> */}
        <div className="link" onClick={openNearToMeFun}>
          <i className="fas fa-location"></i>
          <span>NearToMe</span>
        </div>
        <div className="link" onClick={openToolsFun}>
          <i className="fas fa-tools"></i>
          <span>Tools</span>
        </div>
        <div className="link" onClick={openContactFun}>
          <i className="fas fa-headset"></i>
          <span>Contact Us</span>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
