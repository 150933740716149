import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as apiUrl from '../../helpers/api';
import * as url from '../../helpers/url';
import { profileUpdateAsync } from './AuthSlice';

export const ProfilePostAsync = createAsyncThunk(
  'post/ProfilePostAsync',
  async ({ id, page, search, toast, navigate,dispatch }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.ProfilePost(id, page, search);
      if (response.data.status) {
        // toast.success(response.data.message);
        if (page === 1) {
          dispatch(setProfileposts(response.data.data));
        } else {
          dispatch(addProfileposts(response.data.data));
        }
        dispatch(setHasMore(response.data.data.length > 0));
      } else {
        // toast.error(response.data.message);
      }
      const ProfilePostsData = response.data.data;
      return { ProfilePostsData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postDeleteAsync = createAsyncThunk(
  'post/postDeleteAsync',
  async ({ id, slug, dispatch, navigate, toast }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.postDelete(id, slug);
      if (response.data.status) {
        toast.success(response.data.message);
        // dispatch(ProfilePostAsync({ id, toast }));
        navigate(url.myProfile());
      } else {
        toast.error(response.data.message);
      }
      const postUpdateData = response.data.data;
      return { postUpdateData };
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postCreateAsync = createAsyncThunk(
  'post/postCreateAsync',
  async (
    { id, postData, dispatch, navigate, toast },
    { rejectedWithValue }
  ) => {
    try {
      const response = await apiUrl.postCreate(postData, id);
      if (response.data.status) {
        toast.success(response.data.message);
        dispatch(ProfilePostAsync({ id, toast }));
        navigate(url.myProfilePost());
      } else {
        toast.error(response.data.message);
      }
      const postSubmitData = response.data.data;
      return { postSubmitData };
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postShowAsync = createAsyncThunk(
  'post/postShowAsync',
  async ({ slug, toast }, { rejectedWithValue }) => {
    try {
      const response = await apiUrl.postShow(slug);
      if (response.data.status) {
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message);
      }
      const postShowData = response.data.data;
      return { postShowData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postUpdateAsync = createAsyncThunk(
  'post/postUpdateAsync',
  async (
    { id, slug, postData, dispatch, navigate, toast },
    { rejectedWithValue }
  ) => {
    try {
      const response = await apiUrl.postUpdate(postData, id, slug);
      if (response.data.status) {
        toast.success(response.data.message);
        dispatch(ProfilePostAsync({ id, toast }));
        navigate(url.myProfilePost());
      } else {
        toast.error(response.data.message);
      }
      const postUpdateData = response.data.data;
      return { postUpdateData };
    } catch (err) {
      toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postAllAsync = createAsyncThunk(
  'post/postAllAsync',
  async ({ authid, page, search, toast, navigate,dispatch }, { rejectedWithValue }) => {
    try {
      var response = [];
      if (search && search.length > 0) {
      } else {
        search = undefined;
      }
      if (authid) {
      } else {
        authid = undefined;
      }
      response = await apiUrl.postAll(authid, page, search);
      if (response.data.status) {
        // toast.success(response.data.message);
        if (page === 1) {
          dispatch(setAllPost(response.data.data));
        } else {
          dispatch(addAllPost(response.data.data));
        }
        dispatch(setHasMore(response.data.data.length > 0));
      } else {
        // toast.error(response.data.message);
      }
      const postData = response.data.data;
      console.log('postData = response.data.data', postData);
      return { postData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postAllRelevantAsync = createAsyncThunk(
  'post/postAllRelevantAsync',
  async ({ authid, page, search, toast, navigate, dispatch }, { rejectedWithValue }) => {
    try {
      var response = [];
      if (search && search.length > 0) {
      } else {
        search = undefined;
      }
      if (authid && authid.length > 0) {
      } else {
        authid = undefined;
      }
      response = await apiUrl.postAll(authid, page, search);
      if (response.data.status) {
        // toast.success(response.data.message);
        if (page === 1) {
          dispatch(setAllRelevant(response.data.data));
        } else {
          dispatch(addAllRelevant(response.data.data));
        }
        dispatch(setHasMore(response.data.data.length > 0));
      } else {
        // toast.error(response.data.message);
      }
      const postRelevantData = response.data.data;
      return { postRelevantData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

export const postAllByTagAsync = createAsyncThunk(
  'post/postAllByTagAsync',
  async ({ page, tagId, toast, navigate,dispatch }, { rejectedWithValue }) => {
    try {
      var response = await apiUrl.postAllByTag(page, tagId);
      if (response.data.status) {
        if (page === 1) {
          dispatch(setPostByTags(response.data.data));
        } else {
          dispatch(addPostByTags(response.data.data));
        }
        dispatch(setHasMore(response.data.data.length > 0));
        // toast.success(response.data.message);
      } else {
        // toast.error(response.data.message);
      }
      const postByTagData = response.data.data;
      return { postByTagData };
    } catch (err) {
      // toast.error(err.response.data.message);
      return rejectedWithValue(err.response.data.message);
    }
  }
);

const postSlice = createSlice({
  name: 'post',
  initialState: {
    post: {},
    pageCount: 1,
    hasMore: true,
    searchKeyword: '',
    posts: [],
    postLists: [],
    postRelevants: [],
    postRelevantLists: [],
    postByTags: [],
    postByTagLists: [],
    postCreate: {},
    postupdate: {},
    postdelete: {},
    profileposts: [],
    profilePostLists: [],
    error: '',
    loading: false,
  },
  reducers: {
    searchKeywordAsync: (state, action) => {
      state.searchKeyword = action.payload;
    },
    setPage: (state, action) => {
      state.pageCount = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setPostByTags: (state, action) => {
      state.postByTagLists = action.payload;
    },
    addPostByTags: (state, action) => {
      state.postByTagLists = [...state.postByTagLists, ...action.payload];
    },
    setProfileposts: (state, action) => {
      state.profilePostLists = action.payload;
    },
    addProfileposts: (state, action) => {
      state.profilePostLists = [...state.profilePostLists, ...action.payload];
    },
    setAllPost: (state, action) => {
      state.postLists = action.payload;
    },
    addAllPost: (state, action) => {
      state.postLists = [...state.postLists, ...action.payload];
    },
    setAllRelevant: (state, action) => {
      state.postRelevantLists = action.payload;
    },
    addAllRelevant: (state, action) => {
      state.postRelevantLists = [...state.postRelevantLists, ...action.payload];
    },
  },
  extraReducers: (builder) => {
    //   post list
    builder
      .addCase(postAllAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postAllAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.posts = payload.postData;
        console.log('post fetch sucessfully!');
      })
      .addCase(postAllAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   post relevant list
    builder
      .addCase(postAllRelevantAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postAllRelevantAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.postRelevants = payload.postRelevantData;
        console.log('post relevant list fetch sucessfully!');
      })
      .addCase(postAllRelevantAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   post by keyword list
    builder
      .addCase(postAllByTagAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postAllByTagAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.postByTags = payload.postByTagData;
        console.log('post by tag list fetch sucessfully!');
      })
      .addCase(postAllByTagAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    // post create
    builder
      .addCase(postCreateAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postCreateAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.postCreate = payload.postSubmitData;
        console.log('post create sucessfully!');
      })
      .addCase(postCreateAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    // profile posts
    builder
      .addCase(ProfilePostAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(ProfilePostAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.profileposts = payload.ProfilePostsData;
        console.log('Profile post fetch sucessfully!');
      })
      .addCase(profileUpdateAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   post show
    builder
      .addCase(postShowAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postShowAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.post = payload.postShowData;
        console.log('post show data fetch sucessfully!');
      })
      .addCase(postShowAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   post update
    builder
      .addCase(postUpdateAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postUpdateAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.postupdate = payload.postUpdateData;
        console.log('post updated sucessfully!');
      })
      .addCase(postUpdateAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
    //   post delete
    builder
      .addCase(postDeleteAsync.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(postDeleteAsync.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.postdelete = payload.postDeleteData;
        console.log('post deleted sucessfully!');
      })
      .addCase(postDeleteAsync.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { searchKeywordAsync,setPage,setHasMore,setPostByTags,addPostByTags,setProfileposts,addProfileposts,addAllPost,setAllPost,setAllRelevant,addAllRelevant } = postSlice.actions;
export default postSlice.reducer;
