import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { courseAllAsync } from '../redux/slices/CourseSlice';
import moment from 'moment';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import { Link, useNavigate } from 'react-router-dom';
// import * as url from '../helpers/url';
import { postAllAsync } from '../redux/slices/PostSlice';

const Posts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { courses } = useSelector((state) => state.courseReducer);
  const { posts, searchKeyword } = useSelector((state) => state.postReducer);
  const { auth } = useSelector((state) => state.authReducer);
  const [postsData, setPostsData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState(true);

  useEffect(() => {
    setSearch(searchKeyword);
    setPage(1);
    setPostsData([]); // Clear previous search results
    setHasMore(true); // Reset hasMore flag
  }, [searchKeyword]);

  useEffect(() => {
    if (page) {
      setLoading(true);
      if (auth && auth?.id) {
        const authid = auth?.id;
        dispatch(postAllAsync({ authid, page, search, toast, navigate }));
      } else {
        dispatch(postAllAsync({ page, search, toast, navigate }));
      }
    }
  }, [dispatch, navigate, auth, page, search]);

  useEffect(() => {
    // Compare new posts with existing postsData
    const newPosts = posts.filter(
      (newPost) => !postsData.some((oldPost) => oldPost.id === newPost.id)
    );

    // Update postsData only if there are new posts
    if (newPosts.length > 0) {
      setPostsData((prevPosts) => [...prevPosts, ...newPosts]);
    }

    // Update hasMore flag
    setHasMore(posts.length > 0);

    // Reset loading state
    setLoading(false);
  }, [posts, postsData]);

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };
  console.log('page', page);
  return (
    <>
      {/* <section className="home-grid">
        <h1 className="heading">quick options</h1>

        <div className="box-container">
          <div className="box">
            <h3 className="title">likes and comments</h3>
            <p className="likes">
              total likes : <span>25</span>
            </p>
            <a href={link} className="inline-btn">
              view likes
            </a>
            <p className="likes">
              total comments : <span>12</span>
            </p>
            <a href={link} className="inline-btn">
              view comments
            </a>
            <p className="likes">
              saved playlists : <span>4</span>
            </p>
            <a href={link} className="inline-btn">
              view playlists
            </a>
          </div>

          <div className="box">
            <h3 className="title">top tags</h3>
            <div className="flex">
              <a href={link}>
                <i className="fas fa-code"></i>
                <span>development</span>
              </a>
              <a href={link}>
                <i className="fas fa-chart-simple"></i>
                <span>business</span>
              </a>
              <a href={link}>
                <i className="fas fa-pen"></i>
                <span>design</span>
              </a>
              <a href={link}>
                <i className="fas fa-chart-line"></i>
                <span>marketing</span>
              </a>
              <a href={link}>
                <i className="fas fa-music"></i>
                <span>music</span>
              </a>
              <a href={link}>
                <i className="fas fa-camera"></i>
                <span>photography</span>
              </a>
              <a href={link}>
                <i className="fas fa-cog"></i>
                <span>software</span>
              </a>
              <a href={link}>
                <i className="fas fa-vial"></i>
                <span>science</span>
              </a>
            </div>
          </div>

          <div className="box">
            <h3 className="title">popular topics</h3>
            <div className="flex">
              <a href={link}>
                <i className="fab fa-html5"></i>
                <span>HTML</span>
              </a>
              <a href={link}>
                <i className="fab fa-css3"></i>
                <span>CSS</span>
              </a>
              <a href={link}>
                <i className="fab fa-js"></i>
                <span>javascript</span>
              </a>
              <a href={link}>
                <i className="fab fa-react"></i>
                <span>react</span>
              </a>
              <a href={link}>
                <i className="fab fa-php"></i>
                <span>PHP</span>
              </a>
              <a href={link}>
                <i className="fab fa-bootstrap"></i>
                <span>bootstrap</span>
              </a>
            </div>
          </div>

          <div className="box">
            <h3 className="title">become a tutor</h3>
            <p className="tutor">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Perspiciatis, nam?
            </p>
            <a href="teachers.html" className="inline-btn">
              get started
            </a>
          </div>
        </div>
      </section> */}
      <section className="courses">
        <h1 className="heading">our posts</h1>

        <div className="box-container">
          {postsData &&
            postsData.length > 0 &&
            postsData.map((post, i) => (
              <Link to={`/post/${post?.slug}`} key={post?.id ? post?.id : i}>
                <div className="box">
                  <div className="tutor">
                    <img
                      src={
                        post?.belongsToUser?.avatar
                          ? apiFilepath +
                            '/uploads/avatar/' +
                            post?.belongsToUser?.avatar
                          : filepath +
                            '/assets/images/company-logo-1658436134701.png'
                      }
                      alt="avatar"
                      onError={(e) => {
                        e.currentTarget.src =
                          filepath + '/assets/images/user.png';
                      }}
                    />
                    <div className="info">
                      <h3>
                        {post?.belongsToUser?.name
                          ? post?.belongsToUser?.name
                          : 'User'}
                      </h3>
                      <span>
                        {post?.publishTime
                          ? moment(post?.publishTime).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="thumb">
                    <img
                      src={
                        post?.fileUrl
                          ? post.fileUrl
                          : post?.file
                          ? apiFilepath + '/uploads/post/' + post.file
                          : filepath + '/assets/static/no-image.jpg'
                      }
                      alt="post"
                      onError={(e) => {
                        e.currentTarget.src =
                          filepath + '/assets/static/no-image.jpg';
                      }}
                    />
                    <span>
                      <i className="fas fa-eye"></i>
                    </span>
                  </div>
                  <h3 className="title">{post?.title ? post?.title : null}</h3>
                </div>
              </Link>
            ))}
        </div>
        {loading && (
          <div style={{ width: '100%' }} className="btn">
            Loading...
          </div>
        )}
        {!loading && !hasMore && (
          <div style={{ width: '100%' }} className="btn">
            No more data available
          </div>
        )}
        {!loading && hasMore && (
          <button
            style={{ width: '100%' }}
            className="btn"
            onClick={loadMore}
            onMouseEnter={loadMore}
            disabled={loading}
          >
            Load More
          </button>
        )}
      </section>
      {/* <section className="courses">
        <h1 className="heading">our courses</h1>

        <div className="box-container">
          {courses &&
            courses.length > 0 &&
            courses.map((course, i) => (
              <Link
                to={url.courseView(course?.slug)}
                key={course?.id ? course?.id : i}
              >
                <div className="box">
                  <div className="tutor">
                    <img
                      src={
                        course?.belongsToUser?.avatar
                          ? apiFilepath +
                            '/uploads/avatar/' +
                            course?.belongsToUser?.avatar
                          : filepath +'/assets/images/company-logo-1658436134701.png'
                      }
                      alt="avatar"
                      onError={(e) => {
                        e.currentTarget.src = filepath +'/assets/images/user.png';
                      }}
                    />
                    <div className="info">
                      <h3>
                        {course?.belongsToUser?.name
                          ? course?.belongsToUser?.name
                          : 'User'}
                      </h3>
                      <span>
                        {course?.createdAt
                          ? moment(course?.createdAt).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="thumb">
                    {course?.file ? (
                      <img
                        src={apiFilepath + '/uploads/course/' + course?.file}
                        alt="course"
                        onError={(e) => {
                          e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                        }}
                      />
                    ) : (
                      <img
                        src={filepath +'/assets/static/no-image.jpg'}
                        alt="course"
                        onError={(e) => {
                          e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                        }}
                      />
                    )}
                    <span>
                      <i className="fas fa-eye"></i>
                    </span>
                  </div>
                  <h3 className="title">
                    {course?.title ? course?.title : null}
                  </h3>
                </div>
              </Link>
            ))}
        </div>
        <div className="more-btn">
          <Link to={url.courses()} className="inline-option-btn">
            view all courses
          </Link>
        </div>
      </section> */}
    </>
  );
};

export default Posts;
