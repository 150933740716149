import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { apiFilepath, filepath } from '../helpers/urlConfig';
import { Link, useNavigate } from 'react-router-dom';
import { postAllAsync, setPage } from '../redux/slices/PostSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
// import MetaData from '../components/MetaData';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { postLists, pageCount, hasMore, searchKeyword } = useSelector(
    (state) => state.postReducer
  );
  const { auth } = useSelector((state) => state.authReducer);
  // const [title, setTitle] = useState('');
  // const [description, setDescription] = useState('');
  // const [keywords, setKeywords] = useState('');
  // const [author, setAuthor] = useState('');
  // const [image, setImage] = useState('');
  // const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    // setTitle('DigitoLearn: Share, Connect, and Learn Together on a Dynamic Digital Platform.');
    // var description = `Digitolearn: Your Knowledge-Sharing Platform. Connect, Learn, and Grow with Our Vibrant Community. Explore Diverse Topics, Exchange Insights Effortlessly. Start Your Learning Journey Today!`;
    // setDescription(description);
    // var key = `Technology, Tech reviews, Gadgets, Software tutorials, Tech, Cooking and Food, Recipes, Cooking tutorials, Food challenges, Restaurant reviews, Healthy eating tips, Travel, Travel vlogs, Destination guides, Travel tips, Adventure travel, Budget travel, Fitness and Wellness, Workout routines, Fitness challenges, Wellness tips, Yoga tutorials, Meditation guides, Education and Learning, Educational videos, Online courses, Study tips, Learning languages, Academic tutorials, Entertainment, Movie reviews, Celebrity gossip, TV show recaps, Red carpet events, DIY and Crafts, DIY projects, Craft tutorials, Home decor ideas, Art techniques`;
    // setKeywords(key);
    // setAuthor('nearToMe, digitolearn');
    // setImage(filepath + '/assets/static/companylogo.webp');
    // setCurrentUrl(filepath);
  }, []);

  useEffect(() => {
    const search = searchKeyword;
    var page = 1;
    dispatch(setPage(page));
    if (auth && auth?.id) {
      const authid = auth?.id;
      dispatch(
        postAllAsync({ authid, page, search, toast, navigate, dispatch })
      );
    } else {
      dispatch(postAllAsync({ page, search, toast, navigate, dispatch }));
    }
  }, [dispatch, navigate, searchKeyword, auth]);

  const nextPage = () => {
    const search = searchKeyword;
    var page = pageCount + 1;
    if (auth && auth?.id) {
      const authid = auth?.id;
      dispatch(
        postAllAsync({ authid, page, search, toast, navigate, dispatch })
      );
    } else {
      dispatch(postAllAsync({ page, search, toast, navigate, dispatch }));
    }
    dispatch(setPage(page));
  };

  return (
    <>
      {/* <MetaData
        title={title}
        description={description}
        keywords={keywords}
        author={author}
        image={image}
        currentUrl={currentUrl}
      /> */}
      <section className="courses">
        <h1 className="heading">our posts</h1>
        <InfiniteScroll
          dataLength={postLists.length}
          next={nextPage}
          hasMore={hasMore}
          loader={
            <div style={{ width: '100%' }} className="btn">
              Loading...
            </div>
          }
          endMessage={
            <div style={{ width: '100%' }} className="btn">
              No more posts
            </div>
          }
          scrollThreshold={0.9} // Optional threshold to trigger next load
        >
          <div className="box-container">
            {postLists &&
              postLists.length > 0 &&
              postLists.map((post, i) => (
                <Link to={`/post/${post?.slug}`} key={i}>
                  <div className="box">
                    <div className="tutor">
                      <img
                        src={
                          post?.belongsToUser?.avatar
                            ? apiFilepath +
                              '/uploads/avatar/' +
                              post?.belongsToUser?.avatar
                            : filepath +
                              '/assets/images/company-logo-1658436134701.png'
                        }
                        alt="avatar"
                        onError={(e) => {
                          e.currentTarget.src =
                            filepath + '/assets/images/user.png';
                        }}
                      />
                      <div className="info">
                        <h3>
                          {post?.belongsToUser?.name
                            ? post?.belongsToUser?.name
                            : 'User'}
                        </h3>
                        <span>
                          {post?.publishTime
                            ? moment(post?.publishTime).format(
                                'MMMM Do YYYY, h:mm:ss a'
                              )
                            : null}
                        </span>
                      </div>
                    </div>
                    <div className="thumb">
                      <img
                        src={
                          post?.fileUrl
                            ? post.fileUrl
                            : post?.file
                            ? apiFilepath + '/uploads/post/' + post.file
                            : filepath + '/assets/static/no-image.jpg'
                        }
                        alt="post"
                        onError={(e) => {
                          e.currentTarget.src =
                            filepath + '/assets/static/no-image.jpg';
                        }}
                      />
                      <span>
                        <i className="fas fa-eye"></i>
                      </span>
                    </div>
                    <h3 className="title">
                      {post?.title ? post?.title : null}
                    </h3>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <i className="far fa-heart"></i>
                        <span> {post?.likes ? post?.likes : 0} Likes</span>
                      </div>
                      <div>
                        <i className="far fa-eye"></i>
                        <span> {post?.views ? post?.views : 0}  Views</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </InfiniteScroll>
      </section>
      {/* <section className="courses">
        <h1 className="heading">our courses</h1>

        <div className="box-container">
          {courses &&
            courses.length > 0 &&
            courses.map((course, i) => (
              <Link
                to={url.courseView(course?.slug)}
                key={course?.id ? course?.id : i}
              >
                <div className="box">
                  <div className="tutor">
                    <img
                      src={
                        course?.belongsToUser?.avatar
                          ? apiFilepath +
                            '/uploads/avatar/' +
                            course?.belongsToUser?.avatar
                          : filepath +'/assets/images/company-logo-1658436134701.png'
                      }
                      alt="avatar"
                      onError={(e) => {
                        e.currentTarget.src = filepath +'/assets/images/user.png';
                      }}
                    />
                    <div className="info">
                      <h3>
                        {course?.belongsToUser?.name
                          ? course?.belongsToUser?.name
                          : 'User'}
                      </h3>
                      <span>
                        {course?.createdAt
                          ? moment(course?.createdAt).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="thumb">
                    {course?.file ? (
                      <img
                        src={apiFilepath + '/uploads/course/' + course?.file}
                        alt="course"
                        onError={(e) => {
                          e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                        }}
                      />
                    ) : (
                      <img
                        src={filepath +'/assets/static/no-image.jpg'}
                        alt="course"
                        onError={(e) => {
                          e.currentTarget.src = filepath +'/assets/static/no-image.jpg';
                        }}
                      />
                    )}
                    <span>
                      <i className="fas fa-eye"></i>
                    </span>
                  </div>
                  <h3 className="title">
                    {course?.title ? course?.title : null}
                  </h3>
                </div>
              </Link>
            ))}
        </div>
        <div className="more-btn">
          <Link to={url.courses()} className="inline-option-btn">
            view all courses
          </Link>
        </div>
      </section> */}
    </>
  );
};

export default Dashboard;
