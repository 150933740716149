import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postCreateAsync } from '../redux/slices/PostSlice';
import { tagAllAsync } from '../redux/slices/TagSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CreatableSelect from 'react-select/creatable';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const ImageCompression = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { auth } = useSelector((state) => state.authReducer);
  const { tags } = useSelector((state) => state.tagReducer);
  const [id] = useState(auth?.id);
  const [title, setTitle] = useState('');
  const [externalUrl, setExternalUrl] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState();
  const [defaultTag, setDefaultTag] = useState([]);
  const [suggestions, setSuggestions] = useState();
  const [publishDate, setPublishDate] = useState();

  useEffect(() => {
    dispatch(tagAllAsync({ toast }));
  }, [dispatch]);

  const fileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    dispatch(tagAllAsync({ toast }));
    setPublishDate(new Date());
  }, [dispatch]);

  const postSubmit = async (e) => {
    e.preventDefault();

    const publishDateUtc = moment.utc(publishDate).toISOString();
    const postData = new FormData();
    postData.append('title', title);
    postData.append('url', externalUrl);
    postData.append('description', description);
    postData.append('file', file);
    postData.append('tagIds', JSON.stringify(defaultTag));
    postData.append('publishTime', publishDateUtc);
    dispatch(postCreateAsync({ id, postData, dispatch, navigate, toast }));
  };

  useEffect(() => {
    const suggestions = tags.map((tag) => ({
      value: tag.id.toString(),
      label: tag.name,
    }));
    setSuggestions(suggestions);
  }, [tags]);

  const selectTag = (e) =>
    setDefaultTag(
      e.map((option) => ({ value: option.value, label: option.label }))
    );

  const setPublishDateFun = (date) => {
    setPublishDate(date);
  };

  const urlFun = (event) => {
    const { value } = event.target;
    // Regular expression to check if the input is a valid URL
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    if(urlRegex.test(value)){
      setExternalUrl(value);
    };
  };

  // Define your Quill modules
  const Quillmodules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image', 'video'],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button
    ],
  };

  return (
    <section className="form-container">
      <form action="" method="post" encType="multipart/form-data">
        <h3>Image Compression</h3>
        <p>
          Tag <span>*</span>
        </p>
        <div className="box react-tag">
          <CreatableSelect
            options={suggestions}
            onChange={selectTag}
          />
        </div>
        <p>
          Title <span>*</span>
        </p>
        <input
          type="text"
          name="title"
          placeholder="Title"
          required
          maxLength="200"
          className="box"
          onChange={(e) => setTitle(e.target.value)}
        />
        <p>
          Description <span>*</span>
        </p>
        <div className="box">
          <ReactQuill
            value={description}
            onChange={(value) => {
              setDescription(value);
            }}
            modules={Quillmodules}
            className="react-quill"
            theme="snow"
          />
        </div>
        <p>File (Image)</p>
        <input
          type="file"
          accept="image/*"
          className="box"
          onChange={fileUpload}
        />
                 <p>
          Read More<span>*</span>
        </p>
        <input
          type="text"
          name="url"
          placeholder="External Url"
          required
          maxLength="200"
          className="box"
          value=""
          onChange={urlFun}
        />
        <p>Publish Date</p>
        <Datetime
          className="box"
          value={publishDate}
          onChange={setPublishDateFun}
          inputProps={{ placeholder: 'Select Date and Time' }}
        />
        <input
          type="submit"
          value="Submit"
          name="submit"
          className="btn"
          onClick={postSubmit}
        />
      </form>
    </section>
  );
};

export default ImageCompression;
