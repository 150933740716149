import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/AuthSlice';
import postReducer from './slices/PostSlice';
import courseReducer from './slices/CourseSlice';
import tagReducer from './slices/TagSlice';
import commentReducer from './slices/CommentSlice';
import likeViewReducer from './slices/LikeViewSlice';

export default configureStore({
  reducer: {
    authReducer: authReducer,
    postReducer: postReducer,
    courseReducer: courseReducer,
    tagReducer: tagReducer,
    commentReducer: commentReducer,
    likeViewReducer: likeViewReducer,
  },
});
